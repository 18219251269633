import moment from 'moment';
import {
  Subscription,
  SubscriptionStatuses,
} from '../../shared/types/generated';

export const getStatusTextColor = (subscription: Subscription): string => {
  let text = '';
  const status = subscription.status;

  const isAutoRenewalCancelled =
    subscription.is_cancel === true &&
    subscription.cancel_date &&
    moment(Number(subscription.period_end_date)).isAfter(moment());

  if (isAutoRenewalCancelled) {
    return '#e9bd15';
  }

  if(status === SubscriptionStatuses.Active && subscription.has_change_plan){
    return '#e9bd15';
  }

  switch (status) {
    case SubscriptionStatuses.Active:
      text = 'green';
      break;
    case SubscriptionStatuses.Inactive:
      text = '#e9bd15';
      break;
    case SubscriptionStatuses.Cancelled:
      text = 'red';
      break;
    case SubscriptionStatuses.Updated:
      text = 'green';
      break;
  }
  return text;
};

export const getSubscriptionStatusText = (
  subscription: Subscription,
): string => {
  let text = '';
  const status = subscription.status;

  console.log(subscription); 
  const isAutoRenewalCancelled =
    subscription.is_cancel === true &&
    subscription.cancel_date &&
    moment(Number(subscription.period_end_date)).isAfter(moment());

  if (isAutoRenewalCancelled) {
    return 'Renovación cancelada';
  }

  if(status === SubscriptionStatuses.Active && subscription.has_change_plan){
    return 'Actualización pendiente';
  }

  switch (status) {
    case SubscriptionStatuses.Active:
      text = 'Activo';
      break;
    case SubscriptionStatuses.Inactive:
      text = 'Vencido';
      break;
    case SubscriptionStatuses.Cancelled:
      text = 'Cancelado';
      break;
    case SubscriptionStatuses.Updated:
      text = 'Activo';
      break;
  }
  return text;
};
