import { createAction } from '@cobuildlab/react-simple-state';
import {
  checkoutPlanSubscriptionEvent,
  checkoutPlanSubscriptionEventError,
  updatePlanSubscriptionEvent,
  updatePlanSubscriptionEventError,
} from './stripe-events';
import { stripe } from '../../shared/stripe/config';
import Stripe from 'stripe';

export const checkoutPlanSubscription = createAction(
  checkoutPlanSubscriptionEvent,
  checkoutPlanSubscriptionEventError,
  async (props: {
    email: string;
    priceId: string;
    stateUrl: string;
    metadata?: Record<string, string>;
    coupon?: string;
  }): Promise<void> => {
    const origin = window.location.origin;
    const url = await window.location.href;

    let discount: Stripe.PromotionCode | null = null;
    if (props.coupon) {
      const promotionCodes = await stripe.promotionCodes.list({
        code: props.coupon,
      });

      if (promotionCodes.data.length > 0) {
        discount = promotionCodes.data[0];
      }
    }

    const session = await stripe.checkout.sessions.create({
      mode: 'subscription',
      locale: 'es',
      customer_email: props.email,
      line_items: [
        {
          price: props.priceId,
          quantity: 1,
        },
      ],
      success_url: `${origin}?session_id={CHECKOUT_SESSION_ID}&${props.stateUrl}`,
      cancel_url: `${url}?returningStripe=true`,
      metadata: props.metadata,
      ...(discount && {
        discounts: [
          {
            promotion_code: discount.id,
          },
        ],
      }),
    });

    if (!session.url) {
      throw new Error('Error al crear la sesion de pagos.');
    }

    window.location.href = session.url;

    return;
  },
);

export const updatePlanSubscription = createAction(
  updatePlanSubscriptionEvent,
  updatePlanSubscriptionEventError,
  async (props: { subscriptionId: string; priceId: string }): Promise<void> => {
    const subscription = await stripe.subscriptions.retrieve(
      props.subscriptionId,
    );
    const subscriptionItem = subscription.items.data[0];

    if(subscriptionItem.plan.interval === 'year') {
      console.error('No se puede realizar el cambio de plan de una subscripcion anual.');
      throw new Error('No se puede realizar el cambio de plan.');
    }

    const price = await stripe.prices.retrieve(props.priceId);

    if (!price) {
      console.error('No se encontro el plan seleccionado.');
      throw new Error('No se encontro el plan seleccionado.');
    }

    const newPriceAnnal = price?.recurring?.interval === 'year';

    await stripe.subscriptions.update(props.subscriptionId, {
      items: [
        {
          id: subscriptionItem.id,
          price: props.priceId,
        },
      ],
      proration_behavior: 'none',
      metadata:{
        update_date: new Date().toISOString(),
      },
      ...(newPriceAnnal && {
        proration_behavior: 'create_prorations',
      }),
    });

    return;
  },
);
