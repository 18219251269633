import { createEvent } from '@cobuildlab/react-simple-state';


export const checkoutPlanSubscriptionEvent = createEvent<void>();

export const checkoutPlanSubscriptionEventError = createEvent<Error>();

export const updatePlanSubscriptionEvent = createEvent<void>();

export const updatePlanSubscriptionEventError = createEvent<Error>();
