import React, { ChangeEvent } from 'react';
import {
  TextField,
  OutlinedTextFieldProps,
  Box,
  MenuItem,
} from '@mui/material';

interface InputDefaultProps extends OutlinedTextFieldProps {
  onChangeData: (field: string, value: string) => void;
  options?: { value: string; label: string; disabled?: boolean }[];
  isStandard?: boolean;
}

export const SelectDefault: React.FC<InputDefaultProps> = ({
  onChangeData,
  size = 'medium',
  variant = 'outlined',
  options = [],
  isStandard = false,
  ...rest
}) => {
  const onChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ): void => {
    onChangeData(event.target.name, event.target.value);
  };

  return (
    <Box>
      <TextField
        size={size}
        fullWidth
        onChange={onChange}
        variant={isStandard ? 'standard' : variant}
        select
        InputLabelProps={{
          shrink: true,
          style: { fontSize: 19 },
        }}
        {...rest}>
        {options.map((option) => (
          <MenuItem
            key={option.value}
            value={option.value}
            disabled={option.disabled}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>
    </Box>
  );
};
