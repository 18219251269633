export const termAndCondition = [
  <>
    La contratación del{' '}
    <strong>
      Plan Profesional incluye un kit de bienvenida y dos suscripciones a Animal
      Político
    </strong>{' '}
    válidas por un año ―será elección del comprador quién recibe dichos
    beneficios―.
  </>,
  <>
    La contratación del{' '}
    <strong>Plan Estándar incluye dos suscripciones a Animal Político</strong>{' '}
    válidas por un mes ―será elección del comprador quién recibe el plan
    adicional―.
  </>,
  <>
    La contratación del{' '}
    <strong>
      Plan Estudiantil incluye dos suscripciones a Animal Político
    </strong>{' '}
    válidas por un mes ―será elección del comprador quién recibe el plan
    adicional―.
  </>,
  <>Vigencia al 6 de enero de 2025.</>,
  <>No acumulables con otras promociones.</>,
];
