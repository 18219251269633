import { useCallAction, useStore } from '@cobuildlab/react-simple-state';
import { changePlanStore } from '../payment/payment-events';
import { Theme, Typography } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { ButtonDefault } from '../../shared/components/ButtonDefault';
import { PRIMARY_COLOR } from '../../shared/css/theme';
import {
  checkoutPlanSubscription,
  updatePlanSubscription,
} from './stripe-actions';
import { useAuth } from '../auth/auth-hook';
import { getPostUrl } from '../auth/auth-utils';
import {
  PaymentMethod,
  StripeCoupon,
  Subscription,
  SubscriptionBillingInput,
  useUpdateSubscriptionMutation,
} from '../../shared/types/generated';
import { openDialogAlert } from '../alert/alert-actions';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles<Theme>(() =>
  createStyles({
    icon: {
      color: '#999999',
      fontSize: '20px!important',
      paddingRight: '5px',
    },
    input: {
      background: 'white',
      fontSize: '18px',
      height: '48px',
    },
    button: {
      borderRadius: '5px!important',
      backgroundColor: `${PRIMARY_COLOR}!important`,
      border: 'none!important',
      color: 'white!important',
      '&:disabled': {
        backgroundColor: '#FFCCCC!important',
      },
      height: 45,
    },
  }),
);

interface StripeButtonProps {
  subscriptionBilling: SubscriptionBillingInput | null;
  coupon?: StripeCoupon | null;
  activeSubscription?: Subscription;
}

export const StripeButton: React.FC<StripeButtonProps> = ({
  subscriptionBilling,
  coupon,
  activeSubscription,
}) => {
  const [loading, setLoading] = useState(false);
  const classes = useStyles();
  const { user } = useAuth();
  const { plan } = useStore(changePlanStore);
  const navigate = useNavigate();

  const [updateUserSubscription] = useUpdateSubscriptionMutation({
    refetchQueries: ['CurrentUser'],
    awaitRefetchQueries: true,
    onCompleted: (data) => {
      if (data?.updateSubscription) {
        setLoading(false);
        openDialogAlert('Suscripción actualizada', () =>
          navigate('/suscripciones'),
        );
      }
    },
    onError: (error) => {
      setLoading(false);
      console.error(error);
      openDialogAlert('Error al actualizar la suscripción.');
    },
  });

  const [callCheckoot] = useCallAction(checkoutPlanSubscription, {
    onError: (error) => {
      setLoading(false);
      console.error(error);
    },
  });

  const [callUpdate, loadingUpdate] = useCallAction(updatePlanSubscription, {
    onCompleted() {
      updateUserSubscription({
        variables: {
          data: {
            id: activeSubscription?.id,
            payment_method_type: PaymentMethod.StripeCard,
            plan_id: plan?.id,
            subscription_id: activeSubscription?.transaction_id as string,
            subscriptionBilling,
          },
        },
      });
    },
    onError: (error) => {
      setLoading(false);
      console.error(error);
      openDialogAlert(
        'Error al actualizar la suscripción. No se pudo realizar el pago, intente nuevamente',
      );
    },
  });

  const handleCheckout = (): void => {
    if (!plan?.stripe || !user) {
      return;
    }

    const subscriptionBillingChecked =
      subscriptionBilling &&
      Object.keys(subscriptionBilling).find(
        (key) => !subscriptionBilling[key as keyof SubscriptionBillingInput],
      );

    if (
      plan?.subscription_billing_is_required &&
      subscriptionBillingChecked &&
      !user.subscription_billing
    ) {
      openDialogAlert('Los datos de envio son obligatorios para el plan anual');
      return;
    }

    setLoading(true);

    if (activeSubscription) {
      callUpdate({
        subscriptionId: activeSubscription?.transaction_id as string,
        priceId: plan.stripe?.price_id || '',
      });
      return;
    }

    callCheckoot({
      email: user?.email,
      priceId: plan.stripe?.price_id || '',
      stateUrl: `post_url=${getPostUrl()}`,
      metadata: plan.subscription_billing_is_required
        ? {
            subscriptionBilling: JSON.stringify(subscriptionBilling),
            post_url: getPostUrl(),
          }
        : undefined,
      coupon: coupon?.coupon ?? undefined,
    });
  };

  if (!plan?.stripe) {
    return (
      <Typography variant="subtitle1" fontWeight={800} textAlign="center">
        Stripe no esta configurado para este plan, contacte a su administrador
      </Typography>
    );
  }

  return (
    <ButtonDefault
      variant="contained"
      size="large"
      fullWidth
      isLoading={loading || loadingUpdate}
      onClick={() => handleCheckout()}
      className={classes.button}
    >
      Suscribirse ahora
    </ButtonDefault>
  );
};
