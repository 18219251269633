import React, { useEffect, useState } from 'react';
import {
  PayPalButtons,
  PayPalScriptProvider,
  FUNDING,
} from '@paypal/react-paypal-js';
import { useAuth } from '../auth/auth-hook';
import { useStore } from '@cobuildlab/react-simple-state';
import { changePlanStore } from '../payment/payment-events';
import { openDialogAlert } from '../alert/alert-actions';
import {
  StripeCoupon,
  Subscription,
  SubscriptionBillingInput,
  useCreatePaypalSubscriptionMutation,
} from '../../shared/types/generated';
import { Typography } from '@mui/material';
import { OnApproveData } from '@paypal/paypal-js/types/components/buttons';
import { getPostUrl } from '../auth/auth-utils';
import { APP_ENDPOINT, PAYPAL_CLIENT_ID } from '../../shared/constans';

type PaypalProps = {
  subscriptionBilling: SubscriptionBillingInput | null;
  coupon?: StripeCoupon | null;
  activeSubscription?: Subscription;
};

const PaypalButton: React.FC<PaypalProps> = ({
  subscriptionBilling,
  coupon,
  activeSubscription,
}) => {
  const { user } = useAuth();
  const { plan } = useStore(changePlanStore);
  const [billingDisabled, setBillingDisabled] = useState(true);

  const [callSubscriptionAction] = useCreatePaypalSubscriptionMutation({
    onCompleted: () => {
      // TODO: CAMBIAR URL DE VERIFICACIÓN
      window.location.href =
        APP_ENDPOINT + '/agradecimiento?redirect_url=' + getPostUrl();
    },
    onError: (error) => {
      openDialogAlert(error.message);
    },
  });

  useEffect(() => {
    const subscriptionBillingChecked =
      subscriptionBilling &&
      Object.keys(subscriptionBilling).find(
        (key) =>
          !subscriptionBilling[key as keyof SubscriptionBillingInput] &&
          key !== 'phone1',
      );

    if (
      plan?.subscription_billing_is_required &&
      subscriptionBillingChecked &&
      !user?.subscription_billing
    ) {
      setBillingDisabled(true);
      return;
    }
    setBillingDisabled(false);
  }, [subscriptionBilling, plan, user]);

  const paypalButtonKey = `${plan?.paypal?.paypal_id}-${coupon?.paypal_coupon_plan}`;

  const handleSubscription = async (data: OnApproveData): Promise<void> => {
    const { subscriptionID, orderID } = data;
    callSubscriptionAction({
      variables: {
        data: {
          planId: plan?.id,
          subscriptionId: subscriptionID,
          orderId: orderID,
          subscriptionUrl: getPostUrl(),
          billingInfo: subscriptionBilling,
          coupon: coupon?.coupon ?? undefined,
        },
      },
    });
  };

  return plan && plan.paypal ? (
    <PayPalButtons
      key={paypalButtonKey}
      fundingSource={FUNDING.PAYPAL}
      disabled={billingDisabled || !!activeSubscription}
      createSubscription={async (data, actions) => {
        return actions.subscription
          .create({
            plan_id:
              coupon?.paypal_coupon_plan && coupon.paypal_coupon_plan !== ''
                ? coupon?.paypal_coupon_plan
                : (plan?.paypal?.paypal_id as string),
            subscriber: {
              email_address: user?.email,
            },
            custom_id: user?.email,
          })

          .then((orderId: string) => {
            return orderId;
          });
      }}
      style={{ label: 'subscribe' }}
      onApprove={handleSubscription}
      onError={(err) => {
        console.log({ err });
        return openDialogAlert(
          'Tenemos problemas para realizar la suscripción',
        );
      }}
    />
  ) : null;
};

export const Paypal: React.FC<PaypalProps> = ({
  subscriptionBilling,
  coupon,
  activeSubscription,
}) => {
  const { plan } = useStore(changePlanStore);

  if (!plan?.paypal)
    return (
      <Typography variant="subtitle1" fontWeight={800} textAlign="center">
        Paypal no esta configurado para este plan, contacte a su administrador
      </Typography>
    );
  return (
    <PayPalScriptProvider
      options={{
        clientId: PAYPAL_CLIENT_ID,
        components: 'buttons',
        intent: 'subscription',
        vault: true,
      }}>
      <PaypalButton
        subscriptionBilling={subscriptionBilling}
        coupon={coupon}
        activeSubscription={activeSubscription}
      />
    </PayPalScriptProvider>
  );
};
